/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        homePage: allWpPage(filter: {slug: {eq: "home-seoul"}}) {
          edges {
            node {
              acf {
                metaPageDescription
              }
            }
          }
        }
        aboutPage: allWpPage(filter: {slug: {eq: "about-us"}}) {
          edges {
            node {
              acf {
                spotlightImage {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `
  )

  const { site, allWpPage } = data

  const metaDescription = description === '' ? data.homePage.edges[0]?.node?.acf?.metaPageDescription : description 
  const ogImage = image || data.aboutPage.edges[0]?.node?.acf?.spotlightImage?.sourceUrl;

  const googleVerification = process.env.GATSBY_OFFICE_NAME === 'WK_TOKYO' ? "QZZwftFEXwiIownKaMnIA8wfXeXZ20SyIlB05PaQcGI" : "bM-2SYl4KzLJS62QHqWUG5jg_iYCwfd1cqDPWQhJPeg"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      image={ogImage}
      description={metaDescription}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: googleVerification,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
